<template>
  <div>
    <h1 class="mb-0">{{ $t("Login") }}</h1>
    <span> {{ $t("LoginInfo") }} </span>
    <sign-in-form formType="jwt" email="" password=""></sign-in-form>
  </div>
</template>

<script>
  import SignInForm from '../AuthPages/Forms/SignInForm'

  export default {
    name: 'SignIn',
    components: { SignInForm },
    data: () => ({}),
    mounted() {
      const loggedIn = this.$store.getters['Auth/isLoggedIn']
      if (loggedIn !== undefined && loggedIn !== null && loggedIn.length > 0) {
        this.$router.push({ name: 'home.dashboard' })
      }
    }
  }
</script>
