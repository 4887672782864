<template>
  <b-alert id="no-data-alert" :show="true" :variant="alertStyle" style="margin-bottom: 0px !important">
    <div class="iq-alert-icon">
      <font-awesome-icon :icon="iconCssClass" size="2xl" />
    </div>
    <div class="iq-alert-text text-dark no-data-text">{{ $t(placeholderTextKey) }}</div>
  </b-alert>
</template>

<script>
import axios from '../../services/index'
import authService from '../../services/auth'

export default {
  name: "SsoHandler",
  props: {
    sid: String
  },
  data() {
    return {
      exchangeStatus: 'waiting',
    }
  },
  computed: {
    iconCssClass() {
      if (this.exchangeStatus === 'waiting')
        return 'fa-regular fa-check-circle';
      else if (this.exchangeStatus === 'failed')
        return 'fa-regular fa-triangle-exclamation';
      else if (this.exchangeStatus === 'completed')
        return 'fa-regular fa-check-circle';
      else
        return 'fa-regular fa-triangle-exclamation';
    },
    placeholderTextKey() {
      if (this.exchangeStatus === 'waiting')
        return 'VegaSsoHandler_WaitingForExchange';
      else if (this.exchangeStatus === 'failed')
        return 'VegaSsoHandler_ExchangeFailed';
      else if (this.exchangeStatus === 'completed')
        return 'VegaSsoHandler.ExchangeSucceeded';
      else
        return 'VegaSsoHandler_ExchangeFailed';
    },
    alertStyle() {
      if (this.exchangeStatus === 'waiting')
        return 'info';
      else if (this.exchangeStatus === 'failed')
        return 'danger';
      else if (this.exchangeStatus === 'completed')
        return 'success';
      else
        return 'danger';
    },

  },
  methods: {
    doLogin(loginResult) {
      authService.doLogin(loginResult)
        .then((response) => {
          if (!response.user) {
            this.$toastr.error(this.$t("LoginFailed"));
          } else {

            if (response.user.language && response.user.language.length > 0) {
              this.$bus.$emit('UiLanguageChanged', response.user.language);
            }

            this.$router.push({ name: 'home.dashboard' })
          }
        });
    },
    switchBranch(token, branchId) {
      if (token && branchId && token.length > 10 && branchId.length > 10) {
        this.exchangeStatus = 'completed';
        axios.post('/api/auth/switchBranch', { NewBranchID: branchId }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then((response) => {
          if (response.status === 200) {
            this.exchangeStatus = 'completed';
            this.doLogin(response.data);
          } else {
            this.exchangeStatus = 'failed';
            throw new Error('Switch branch request failed with status: ' + response.status);
          }
        });
      }
    },
    exchangeSid() {
      const $this = this;
      this.exchangeStatus = 'waiting';

      axios.post('/api/vegaSso/exchangeSid', { sid: this.sid })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            var token = response.data.token;
            var branchId = response.data.branchId;
            $this.switchBranch(token, branchId);
          } else {
            this.exchangeStatus = 'failed';
          }
        })
        .catch((error) => {
          //this.exchangeStatus = 'failed';
          throw error;
        });
    }
  },
  mounted() {
    if (this.sid && this.sid.length == 48) {
      this.exchangeSid();
    }
    else {
      this.isExchangeFailed = true;
      authService.logout();
    }
  }
}
</script>

<style scoped>
#no-data-alert .alert-warning {
  color: #e9a90f !important;
  border-color: #fff2b2 !important;
  background-color: #fff7e0 !important;
}

#no-data-alert .no-data-text {
  font-size: 1rem !important;
  font-weight: 500 !important;
}
</style>
